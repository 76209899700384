h1 {
    font-family: sans-serif;
    font-weight: bold;
    color: white;
    /* padding-left: 10px; */
    padding-bottom: 15px;
    padding-top: 25px;
    margin: 0;
    text-align: center;
}
.text {
    color: white;
    padding-left: 315px;
    padding-right: 315px;
    padding-bottom: 4px;
    text-align: center;
    font-size: large;
}
a {
    color:aliceblue;
    text-decoration: none;
}