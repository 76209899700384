.content-wrap {
    flex:1;
    align-items: center;
}
/* 
NavBar
*/

nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgb(56, 0, 60, 0.5);
    color: white;
    position: sticky;
    top: 0;
}

nav .title {
    font-size: 1.75rem;
    margin: 1rem;
    font-weight: bold;
    text-decoration: none;
    color: white;
}

nav ul {
    display: flex;
}

nav ul li {
   list-style: none; 
}

nav ul li a {
    display: block;
    text-decoration: none;
    color: white;
    padding: 0.5rem;
    margin: 0 0.5rem;
    border-radius: 0.5rem;
}

nav ul li a:not(.active):hover {
    background-color:rgb(233, 0, 82, 0.5);
}

nav .menu {
    display: none;
    position: absolute;
    top: 0.75rem;
    right: 0.5rem;
    flex-direction: column;
    justify-content: space-between;
    width: 2.25rem;
    height: 2rem;
}

nav .menu span {
    height: 0.4rem;
    width: 100%;
    background-color: #fff;
    border-radius: 0.2rem;
}

@media (max-width: 480px) {
    nav .menu {
        display: flex;
    }

    nav {
        flex-direction: column;
        align-items: flex-start;
    }

    nav ul {
        display: none;
        flex-direction: column;
        width: 100%;
        margin-bottom: 0.25rem;
    }

    nav ul.open {
        display: flex;
    }

    nav ul li {
        width: 100%;
        text-align: center;
    }

    nav ul li a {
        margin: 0.2rem 0.5rem;
    }
}

/* 
Footer 
*/

.main-footer {
    color: #fff;
    background-color:rgb(56, 0, 60, 0.5);
    /* padding-top: 2em; */
    position: relative;
    bottom: 0;
    width: 100%;
    text-align: center;
}

/* 
Table
*/

.table-container {
    position: relative;
    left: 50%;
    max-height: 600px;
    max-width: 800px;
    overflow-y: scroll;
    overflow-x: scroll;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
}
  table {
    width: 800px;
    height: 800px;
    margin: auto;
    border-collapse: collapse;
    overflow: hidden;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  }
  th,
  td {
    padding: 15px;
    background-color: rgba(255, 255, 255, 0.2);
    color: #fff;
  }
  th {
    text-align: center;
  }
  thead th {
    position: relative;
    background-color: #38003c;
  }
  thead th:hover {
    background-color: rgba(233, 0, 82, 0.3);
  }
  tbody tr:hover {
    background-color: rgba(255, 255, 255, 0.3);
  }
  tbody td {
    text-align: center;
    position: relative;
  }
  tbody td:hover:before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: -9999px;
    bottom: -9999px;
    background-color: rgba(255, 255, 255, 0.2);
    z-index: -1;
  }

/* 
search bar
*/
.search {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    margin-top: 10px;
    border: none;
}

input.search {
    color:#fff;
    background-color:rgb(56, 0, 60, 0.2);
    font-family: sans-serif;
    font-weight: 200;
    width: 200px;
    height: 20px;
    text-align: center;
}
    input.search:focus{
        outline: none;
        text-align: center;
    }
    input.search:focus::placeholder{
        opacity: 0;
    }

    input.search::placeholder{
        color:#fff;
        box-shadow: none;
        text-align: center;
    }