html,
body {
  height: 100%;
}
body {
  margin: 0;
  background: -webkit-linear-gradient(45deg, #00ff85, #e90052);
  background: linear-gradient(45deg, #00ff85, #e90052);
  font-family: sans-serif;
  font-weight: 100;
}

.page-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content-wrap {
  flex:1;
}

